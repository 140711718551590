import Layout from "@components/layouts/Layout";
import axios from "axios";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Seo from "@components/common/Seo";
import RenderLoading from "./components/loading";
// import { Link } from "gatsby";
import { toast } from "react-toastify";

// Importamos el idioma español para dayjs y lo activamos
require("dayjs/locale/es");
dayjs.locale("es");

// Activamos plugin de dayjs para mostrar la fecha en formato relativo
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

// Fix para que funciona ReactJson
const ReactJsonView = typeof window !== `undefined` ? require("react-json-view").default : null;

const Component = ({ pageContext: { item } }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Capturamos la información adicional del post
        async function fetchData() {
            setLoading(true);

            const toastId = toast.loading("Cargando datos...", {
                position: "bottom-center",
            });

            const rawData = await getRanking(item);

            setData(rawData);
            setLoading(false);
            toast.dismiss(toastId);
        }

        fetchData();
    }, [item]);

    return (
        <Layout>
            <Seo title={item.title} description={item.description} />

            {loading && <RenderLoading />}

            {data && (
                <div className="container py-20 mx-auto">
                    {/*<RenderPosts
                        loading={loading}
                        data={data}
                        paginationBase={`/blog`}
                        firstExpanded={true}
                    />*/}

                    {process.env.NODE_ENV === "development" && (
                        <ReactJsonView src={{ loading, item, data }} collapsed={true} />
                    )}
                </div>
            )}
        </Layout>
    );
};

const getRanking = async item => {
    try {
        const { data } = await axios.post(
            `/.netlify/functions/ranking`,
            {
                moduleId: item.moduleId,
                appId: item.appId,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        return data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default Component;
